import SmoothScroll from 'smooth-scroll';

var scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
  offset: $(window).width() >= 1024 ? 85 : 75,
  speed: 100
});

// import smoothScroll from '../../plugins/smooth-scroll.js';
// //アンカーリンクの場合data-scrollをつける
// $("a[href*='#']").each(function(){
  //   var href = $(this).attr('href');
  //   if(href && href !== "#") {
    //     var id = href.substring(href.indexOf("#"),href.length);
    //     if(($(id).length)){
      //       // console.log(href)
      //       $(this).attr('data-scroll', '');
      //     }
      //   }
      // });
      // //data-scrollがついているものはスムーススクロール
      // smoothScroll.init({
        //   speed: 500,
        //   offset: $(window).width() >= 1024 ? 85 : 75
        // });
