// import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import flexibility from 'flexibility';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import isMobile from './modules/common/is-mobile.js';
import './modules/common/smoothScroll.js';
import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/sp-menu.js';
import './modules/common/megamenu.js';
import './modules/common/datepicker.js';
import './modules/common/tablefix.js';
import './modules/top/hero-swiper.js';
import './modules/top/feature-swiper.js';
import './modules/top/overlay-window.js';
import './modules/top/fade-news.js';
import hljs from "highlight.js/lib/highlight";
import javascript from "highlight.js/lib/languages/javascript";
import css from "highlight.js/lib/languages/css";
import scss from "highlight.js/lib/languages/scss";
import xml from "highlight.js/lib/languages/xml";

flexibility(document.documentElement);//flexboxのie対応
objectFitImages();//object-fitのie対応
svg4everybody();//svgをieで表示

//電話番号スマホのみ掛ける
$("a[href*='tel']").on("click", function(e){
  if(!isMobile(768)){
    return false
  }
});

$(window).scroll(function() {
  if($(window).scrollTop() >= 1) {
    $('body').addClass("is-scrolled");
    // console.log($(window).scrollTop());
  } else {
    $('body').removeClass("is-scrolled");
  }
});
// const hoge = val => val + 1;
// console.log(hoge(1));

//右下のページトップへ戻る
$(window).on('scroll', function(){
  if(150 <= $(window).scrollTop()) {
    // $(".c-scrollToPageTop").addClass("is-active");
    TweenMax.to("#js-pagetop", 0.3, { alpha: 1, y: '0%'});
  } else {
    // $(".c-scrollToPageTop").removeClass("is-active");
    TweenMax.to("#js-pagetop", 0.3, { alpha: 0, y: '100%'});
  }
});


//highlight.js 補正
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("css", css);
hljs.registerLanguage("scss", scss);
hljs.registerLanguage("xml", xml);

$(window).on('load', function() {
   hljs.initHighlighting();
   $('code.hljs').each(function() {
     var code_lang = $(this).attr('data-lang') != undefined ? $(this).attr('data-lang') : $(this).attr("class").split(' ').splice(1, 1)
     // var code_lang = $(this).attr("class").split(' ').splice(1, 1)
     // console.log(code_lang)
     $(this).before('<div class="c-code__lang"><span>' + code_lang + '</span></div>')

   })

 })
