var store = require("store");

//　トップページでアニメーション再生するか判断
var isTopPage = -1 < $.inArray(location.pathname, ["/", "/index.php"]);
var pathname = function () {
  return location.pathname == "/index.php" ? "/" : location.pathname;
};

if (isTopPage) {
  // ==================================================
  // 外部からこのサイトを訪問したユーザーには必ずアニメーションを見せる
  // いちどアニメーションを見せたらサイト内部を回遊している間はアニメーションを見せない
  // ※トップページでリロードした場合は、アニメーションを見せる
  // ==================================================
  var fromOutside = !document.referrer.match(location.host);

  if (fromOutside) {
    store.set("referrer", location.pathname);
  }

  if (window.performance) {
    if (pathname() == store.get("referrer")) {
      // $("body").data("animate", true);
      $("body").data("animate", true);
    }
  }
}
store.set("referrer", pathname());

if ($("body").data("animate")) {
  $("body").addClass("fade-news-bg");
  $(".js-fade-news").css("display", "flex");
}

$(".js-fade-news-close").on("click", function () {
  $(".js-fade-news").fadeOut();
});

document.addEventListener("click", (e) => {
  if (!e.target.closest(".sw-fade-news__inner")) {
    $(".js-fade-news").fadeOut();
  }
});

//リンク無効
$(".js-overlaywindow").on("click", function (e) {
  return !$(e.target).is("a");
});
