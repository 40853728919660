import swiper from 'swiper';

var topHero = new swiper('.js-front-contents-feature-slider', {
  effect: "fade",
  speed: 300,
  autoplay: {
    delay: 5500,
    disableOnInteraction: false,
  },
  loop: true,
  navigation: {
    nextEl: '.front-hero-slider__next',
    prevEl: '.front-hero-slider__prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  }
});



// $('.js-gallery-content').each(function(i, v){
//   var $that = $(this);
//
//   var roomNav = new swiper ($that.find('.js-gallery-nav'), {
//     // Optional parameters
//     direction: 'vertical',
//     loop: false,
//     // slidesPerView: 5,
//     speed:150,
//     autoplay: false,
//     spaceBetween: 8,
//     // centeredSlides: true,
//     observer: true,
//     // observeParents: true,
//     preloadImages: false,
//     // noSwiping: true,
//     slidesPerView: "auto",
//     autoHeight: true,
//     allowTouchMove: false,
//     // watchSlidesVisibility: true,
//     // watchSlidesProgress: true,
//     lazy: {
//       loadPrevNext: true,
//       loadPrevNextAmount: 2
//     },
//     // And if we need scrollbar
//     scrollbar: {
//       el: '.swiper-scrollbar',
//     },
//     breakpoints: {
//       768: {
//         direction: 'horizontal',
//         allowTouchMove: true,
//         spaceBetween: 7
//       }
//     }
//   });
//
//   var roomMain = new swiper ($that.find('.js-gallery-main'), {
//     // Optional parameters
//     direction: 'horizontal',
//     loop: false,
//     effect: "fade",
//     // slidesPerView: 5,
//     speed:150,
//     autoplay: false,
//     spaceBetween: 0,
//     centeredSlides: true,
//     observer: true,
//     // observeParents: true,
//     preloadImages: false,
//     // noSwiping: true,
//     slidesPerView: "auto",
//     autoHeight: true,
//     lazy: {
//       loadPrevNext: true,
//       loadPrevNextAmount: 2
//     },
//     // And if we need scrollbar
//     scrollbar: {
//       el: '.swiper-scrollbar',
//     },
//     thumbs: {
//       // swiper: roomNav
//     },
//     on: {
//       init: function () {
//         $that.find(".js-gallery-nav .swiper-slide").eq(this.activeIndex).addClass("is-active");
//         // underLine(this.activeIndex);
//       },
//     }
//   });
//
//   $that.find('.js-gallery-nav .swiper-slide').on('click', function(){
//     var $this = $(this);
//     var index = roomNav.clickedIndex;
//     roomMain.slideTo(index);
//     // roomNav.slideTo(index);
//     // console.log(index);
//     // console.log(roomNav.progress);
//     // console.log(roomNav.translate);
//     // if(index <= 2) {
//     //   roomNav.setTranslate(0);
//     // } else {
//     //   roomNav.slideTo(index);
//     // }
//     $that.find(".js-gallery-nav .swiper-slide").removeClass("is-active");
//     $that.find(".js-gallery-nav .swiper-slide").eq(index).addClass("is-active");
//
//     // console.log($that.find(".js-gallery-nav .swiper-slide").eq(index));
//
//     // $("is-is-active").
//
//   })
//
// })




// var galleryThumbs = new Swiper('.gallery-thumbs', {
//       spaceBetween: 10,
//       slidesPerView: 4,
//       freeMode: true,
//       watchSlidesVisibility: true,
//       watchSlidesProgress: true,
//     });
//     var galleryTop = new Swiper('.gallery-top', {
//       spaceBetween: 10,
//       navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//       },
//       thumbs: {
//         swiper: galleryThumbs
//       }
//     });

//
// $(".top-mobileCategoryNav .swiper-slide").on('click', function(){
//   var index = topCategorySwiperNav.clickedIndex;
//   topCategorySwiper.slideTo(index);
//   // topCategorySwiperNav.slideTo(index);
//   // console.log(topCategorySwiperNav.clickedIndex);
//   // console.log(topCategorySwiperNav.progress);
//   // console.log(topCategorySwiperNav.translate);
//   if(index <= 2) {
//     topCategorySwiperNav.setTranslate(0);
//   } else {
//     topCategorySwiperNav.slideTo(index);
//   }
//   $(".top-mobileCategoryNav .swiper-slide").removeClass("is-active");
//   $(this).addClass("is-active");
//   setTimeout(function(){
//     underLine(index);
// 	},150);
//
//   // $("is-is-active").
//
// })
//
// topCategorySwiper.on('slideChangeTransitionEnd', function(){
//   var index = topCategorySwiper.activeIndex;
//   if(index <= 2) {
//     topCategorySwiperNav.setTranslate(0);
//   } else {
//     topCategorySwiperNav.slideTo(index);
//   }
//   $(".top-mobileCategoryNav .swiper-slide").removeClass("is-active");
//   $(".top-mobileCategoryNav .swiper-slide").eq(index).addClass("is-active");
//   setTimeout(function(){
//     underLine(index);
//     closePoint = $(".top-categorySlider").offset().top + $(".top-categorySlider").outerHeight();
// 	},150);
// });



// topCategorySwiper.init();
// topCategorySwiperNav.controller.control = topCategorySwiper;
// topCategorySwiper.controller.control = topCategorySwiperNav;
