import isMobile from './is-mobile.js';

//グローバルナビ メガメニュー
$('.js-megamenu .megamenu-wrapper').on({
  'touchstart mouseenter': function touchstartMouseenter() {
    if(!isMobile(1280)){
      $(this).addClass('is-megaMenu--show');
      $("body").addClass('is-megaMenu--showPage');
      $(this).next().find(".st-header-megaMenu").css({"z-index": 500});
      $(this).prev().find(".st-header-megaMenu").css({"z-index": 500});
    }
  },
  'touchend mouseleave': function touchendMouseleave() {
    if(!isMobile(1280)){
      $(this).removeClass('is-megaMenu--show');
      $("body").removeClass('is-megaMenu--showPage');
      $(this).next().find(".st-header-megaMenu").attr({"style": ""});
      $(this).prev().find(".st-header-megaMenu").attr({"style": ""});
    }
  }
});
//グローバルナビ メガメニュー スマホで親をタップしてもリンクしない
$('.js-megamenu .megamenu-wrapper').on("click", function(e){
  if(isMobile(1280)){
    if($(e.target).closest("a").is(".st-header-globalNav__link")) {
      $(this).toggleClass('is-megaMenu--show');
    }
    return ! $(e.target).closest("a").is(".st-header-globalNav__link")
  }
})
