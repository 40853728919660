$(".js-overlaywindow").add(".js-overlaywindow-close").on("click", function(){
  var overlaywindow_num = $(this).data('overlaywindow')
  console.log(overlaywindow_num)

  if(!($("body").hasClass("is-overlaywindow--showPage"))) {

    $('.overlaywindow-container[data-overlaywindow="' + overlaywindow_num + '"] ').addClass("is-overlaywindow--show");
    $("body").removeClass('is-searchContent--hidePage').addClass('is-overlaywindow--showPage');

    // 現在のスクロール位置を取得
    $('html').data('scrTop', $(window).scrollTop());//メニューを開いた位置を保存
    $('body,html').css({"position":"fixed","top": -1 * $('html').data('scrTop'),"height":"100%"});

  } else {
    $('body,html').css({"position":"static","overflow":"visible","height":"auto"});
    setTimeout(function() {
      $('.overlaywindow-container[data-overlaywindow="' + overlaywindow_num + '"] ').removeClass("is-overlaywindow--show");
      $("body").removeClass("is-overlaywindow--showPage");
    }, 100);
    // メニューを開いた時点のスクロール位置を反映
    $(window).scrollTop($('html').data('scrTop'));//メニューを開いた位置へ移動

  }
})

//リンク無効
$('.js-overlaywindow').on("click", function(e){
  return ! $(e.target).is("a")
})
